import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
// import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#EB553C';
const footerRelatedLinks = [
	{
		title: 'Terre des Hommes, My orange',
		url: '/fr/projets/terre-des-hommes-my-orange/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-cover.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-leschauffeurs-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-brandDNA-FR.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-logo.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-arbres.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-pattern.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-carte-visite.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-en-tete.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-t-shirt.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-affiche.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-vehicule-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-vehicule-2.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-vehicule-3.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_12: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-chauffeur-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_13: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-chauffeur-2.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_14: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-siteweb-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/les-chauffeurs-de-remy/',
					lang: 'en',
				},
			]}
			title="Les chauffeurs de Rémy - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Les chauffeurs de Rémy"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Le voyage est aussi important que la destination.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Positionnement de marque',
								'Branding',
								'Identité visuelle',
								'Développement web',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							La société de transport à mobilité réduite Rémy
							Brand allait entrer dans une phase de développement
							avec un accroissement important de la taille de
							l’entreprise et donc du nombre de collaborateurs et
							de véhicules. Cette étape stratégique s’associe à
							des enjeux de reconnaissance de la marque, de
							différenciation sur le marché et aussi d’esprit
							d’appartenance des collaborateurs.
						</p>

						<h3>Notre réponse</h3>
						<p>
							Notre travail de la marque a permis d’identifier la
							singularité du personnage qu’est Rémy Brand, qui
							fait que sa société se différencie du marché. Notre
							processus créatif s’est centré sur l’idée de
							traduire cette « âme » dans une marque à la fois
							simple et forte: Les chauffeurs de Rémy.
						</p>
						<p>
							Un travail de projection et de mapping des risques a
							permis d’identifier les enjeux et les opportunités
							du développement de l’entreprise pour réduire les
							risques de perte d’appartenance dans un
							développement d’entreprise.
						</p>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>ADN de la marque</h3>
							<p>
								Identifier la singularité pour proposer une
								marque forte, cohérente et avec un important
								potentiel de croissance.
							</p>
						</div>
						<Image
							{...data.image_1.childImageSharp}
							alt="ADN de la marque"
						/>
					</div>
				</div>

				<Column modifiers="right">
					<div className="column__text text-content">
						<h3>Naming et logo</h3>
						<p>
							L’entreprise se focalise sur l’humain, sur les
							histoires de vie plutôt que sur le moyen (de
							transport).
						</p>
						<p>
							Ainsi, l’âme de la société n’est pas le moyen, mais
							l’humain. Il s’agit de chauffeurs qui ont un prénom
							et qui provoquent la relation dans le service, comme
							le ferait Rémy. De ce constat, le nom nous a paru
							être une évidence.
						</p>
					</div>

					<div className="column__media text-content">
						<Image {...data.image_2.childImageSharp} alt="Logo" />
					</div>
				</Column>

				<Column modifiers="right" className="grid-spacing">
					<div className="column__text text-content">
						<h3>De l’arbre à la forêt</h3>
						<p>
							La symbolique de la marque Rémy Brand, c’est
							l’arbre. Ce point de référence est le symbole de la
							confiance et de la force tranquille.
						</p>
						<p>
							Alors que le fondateur, Rémy, représentait l’arbre,
							dorénavant les chauffeurs deviennent la forêt.
							Chacun.e a sa forme, ses spécificités, afin que
							cette multitude forme un ensemble cohérent.{' '}
						</p>
					</div>

					<div className="column__media text-content">
						<Image {...data.image_3.childImageSharp} alt="Arbres" />
					</div>
				</Column>

				<Column modifiers="right" className="grid-spacing">
					<div className="column__text text-content">
						<h3>Une forêt en mouvement</h3>
						<p>
							Et cette forêt est en mouvement ! Elle se déplace
							jusque chez vous pour vous emmener vers votre
							destination. C’est une forêt visible sans être
							proéminente.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_4.childImageSharp}
							alt="Pattern"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_5.childImageSharp}
					alt="Cartes de visite"
				/>
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_6.childImageSharp} alt="En-tête" />
					<Image {...data.image_7.childImageSharp} alt="T-shirt" />
				</Gallery>
				<Image
					className="col-full grid-spacing"
					{...data.image_8.childImageSharp}
					alt="Affiche"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="Une forêt en mouvement, un signe de différenciation simple et fort, une histoire qui se raconte."
					more="Sans être proéminent, chaque véhicule est signé de l’identité tout respectant son intégrité. Ainsi, les véhicules deviennent les ambassadeurs de la marque et un signe fort de la présence régionale de la société."
					cite=""
				/>

				<Image
					className="col-full"
					{...data.image_9.childImageSharp}
					alt="Véhicule"
				/>
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_10.childImageSharp} alt="Véhicule" />
					<Image {...data.image_11.childImageSharp} alt="Véhicule" />
				</Gallery>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>
							Un trajet, c’est avant tout une rencontre humaine
						</h3>
						<p>
							Créer une relation authentique en allant à la
							rencontre de l’autre avec humanité et humilité… la
							marque raconte aussi l’histoire des chauffeurs, les
							Kilian, Angela, Roberto ou Maria… qui seront
							l’humain derrière le service.
						</p>
						<p>
							C’est cette humanité sincère et chaleureuse que nous
							désirions retranscrire dans le travail de shooting
							photo pour l’identité visuelle.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_12.childImageSharp}
							alt="Chauffeur"
						/>
						<Image
							{...data.image_13.childImageSharp}
							alt="Chauffeurs"
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_14.childImageSharp}
					alt="Site web"
				/>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
